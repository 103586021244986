import React from 'react'
import { useTranslation } from 'react-i18next'

import { PromoHero } from 'blocks/Heros/PromoHero'
import { RunningLine } from 'components/RunningLine'
import { Advantages3D } from 'blocks/Advantages3D'
import { Visualization3d } from 'blocks/Visualization3d'
import { ProjectStructure } from 'blocks/ProjectStructure'
import { VisualizationAdvantages } from 'blocks/VisualizationAdvantages'
import { AdvantagesServices } from 'blocks/AdvantagesService'
import { Faq } from 'blocks/FAQ/FAQ'
import { ExamplesOfProjects } from 'blocks/ExamplesOfProjects'
import { Reviews } from 'blocks/Reviews'
import { Project3D } from 'blocks/Project3D'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import { SelectionOfPaints } from 'blocks/SelectionOfPaints'
import { Connection } from 'blocks/Connection'
import { CommercialProposalFormBlock } from 'blocks/CommercialProposalFormBlock'
import { ButtonWithModal } from 'components/ButtonWithModal'
import { HelmetFunc } from 'components/PageMetaData'
import { graphql } from 'gatsby'
import { imagesDataProp } from 'types/interfaces'
import { Layout } from 'layout/Layout'
import { Visualization360 } from 'blocks/Vizualization360'

const pageMetadata = {
    uk: {
        title: 'Дизайн проект квартири за $99',
        description:
            "Виконаємо дизайн проект інтер'єру усієї квартири за 99 доларів США",
    },
    ru: {
        title: 'Дизайн проект квартиры за $99',
        description:
            'Выполним дизайн проект интерьера всей квартиры за 99 долларов США',
    },
    en: {
        title: 'Apartment design for $99',
        description:
            'We will design the interior project of the entire apartment for 99 US dollars',
    },
}

const PromoLong = ({ data }: { data: imagesDataProp }) => {
    const { t } = useTranslation()
    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <PromoHero imagesData={data.allImageSharp} />
            <RunningLine>{t('designProject99')}</RunningLine>
            <div id="projectStructure" />
            <ProjectStructure imagesData={data.allImageSharp} />
            <ExamplesOfProjects />
            <Reviews />
            <CommercialProposalFormBlock />
            <RunningLine>{t('designProject99')}</RunningLine>
            <Project3D imagesData={data.allImageSharp} />
            <div id="project3dAdvantages" />
            <Advantages3D imagesData={data.allImageSharp} />
            <Connection text={t('connection.text')}>
                <ButtonWithModal
                    modalTitle={t('connection.modalTitle')}
                    modalDescription={t('connection.modalDescription')}
                    buttonLabel={t('connection.buttonLabel')}
                    placeholder={t('connection.placeholder')}
                    submitLabel={t('connection.submitLabel')}
                    tracking={{
                        conversionType: 'CallbackFromPromo',
                        eventCategory: 'Callback',
                    }}
                />
            </Connection>
            <SelectionOfPaints
                imagesData={data.allImageSharp}
                setAdvantagesServicesIsVisible={() => {}}
            />
            <div id="selectionOfPaintsAdvantages" />
            <AdvantagesServices imagesData={data.allImageSharp} />
            <RunningLine>{t('designProject99')}</RunningLine>
            <div id="visualization3d" />
            <Visualization3d imagesData={data.allImageSharp} />
            <RunningLine inverse>{t('designProject99')}</RunningLine>
            <Visualization360 />
            <div id="visualization3dAdvantages" />
            <VisualizationAdvantages imagesData={data.allImageSharp} />
            <div id="faq" />
            <Faq imagesData={data.allImageSharp} />
            <DefaultFormBlock
                withPhoneMobile
                tracking={{
                    conversionType: 'FormPromoPageBottom',
                    eventCategory: 'Bottomform',
                }}
            />
        </Layout>
    )
}

export default PromoLong

export const query = graphql`
    query {
        allImageSharp {
            edges {
                node {
                    fluid(srcSetBreakpoints: [400]) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
